<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} discounts</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">Found {{meta.total}} discounts that match '{{query}}'</span>
      <router-link
        v-if="can(uiPermissions.DISCOUNTS_CREATE)"
        to="/discounts/new"
        data-test="btn-new-discount"
        class="btn btn-primary ml-auto">
        New discount
      </router-link>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table data-test="table-discount" class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th class="w-1"></th>
                  <th>Discount</th>
                  <th class="text-center">Amount</th>
                  <th class="text-center">Submitted</th>
                  <th class="text-center">Applied</th>
                  <th class="w-1">Last updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <span class="avatar" :style="`background-image: url(${identicon(item.id)});`"></span>
                  </td>
                  <td>
                    <div>
                      <router-link v-if="can(uiPermissions.DISCOUNTS_VIEW)" :to="`/discounts/${item.id}`">
                        #{{item.id}}
                      </router-link>
                      <span v-else>
                        #{{item.id}}
                      </span>
                      for
                      <router-link
                        v-if="can(uiPermissions.CUSTOMERS_VIEW)"
                        :to="`/users/${item.customer.id}`">
                        {{truncate(displayName(item.customer.user))}}
                      </router-link>
                      <span v-else>
                        {{truncate(displayName(item.customer.user))}}
                      </span>
                    </div>
                    <div class="small text-muted">{{truncate(item.reason)}}</div>
                  </td>
                  <td class="text-center">AED {{numeral(item.amount).format('0,0[.]00')}}</td>
                  <td class="text-center">
                    {{moment.utc(item.createdAt).local().fromNow()}}
                  </td>
                  <td class="text-center">
                    <i v-if="item.used" class="fe fe-check"></i>
                    <div v-if="item.used" class="small text-muted">
                      {{moment(item.usedDate).local().format('D MMM YYYY')}}
                    </div>
                  </td>
                  <td>
                    {{moment.utc(item.updatedAt).local().fromNow()}}
                  </td>
                  <td class="text-center">
                    <div v-if="can([uiPermissions.DISCOUNTS_VIEW, uiPermissions.DISCOUNTS_DELETE])" class="item-action dropdown">
                      <a
                        tabindex="0"
                        data-toggle="dropdown"
                        data-test="link-dropdown"
                        class="icon"><i class="fe fe-more-vertical"></i></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <router-link
                          v-if="can(uiPermissions.DISCOUNTS_VIEW)"
                          :to="`/discounts/${item.id}`"
                          data-test="link-edit"
                          class="dropdown-item">
                          <i class="dropdown-icon fe fe-edit"></i> Edit
                        </router-link>
                        <div v-if="can(uiPermissions.DISCOUNTS_DELETE)" class="dropdown-divider"></div>
                        <button
                          v-if="can(uiPermissions.DISCOUNTS_DELETE)"
                          type="button"
                          data-test="btn-delete"
                          class="dropdown-item"
                          @click="handleDelete(item)">
                          <i class="dropdown-icon fe fe-trash"></i> Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="9">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {discounts} from '@/services';

export default {
  components: {
    Pagination,
  },
  mixins: [
    list,
  ],
  methods: {
    fetchData(page) {
      return discounts.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
        with: 'customer.user.customer',
      });
    },
    async handleDelete(item) {
      if (window.confirm('Deleting discount cannot be undone. Are you sure that you want to delete this discount?')) {
        await discounts.deleteById(item.id);
        this.refresh();
      }
    },
  },
};

</script>
